import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//公司报价设置详情
export function getCompanyOfferSetting(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/settings/company-offer-settings",
    method: "get",
    params
  });
}
//创建或更新公司报价设置
export function companyOfferSetting(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/settings/company-offer-settings",
    method: "POST",
    data
  })
}