<template>
  <div class="part_list">
    <div
      class="partsItem mb-8 px-16 py-16 flex"
      :style="{ height: renderHeight(partItem) }"
      v-for="(partItem, s_index) in list"
      :key="s_index"
    >
      <div class="item_info mr-8">
        <div class="flex items-center justify-end flex-wrap">
          <span class="flex-1">
            {{ partItem.partName || partItem.stdPartName }}
          </span>
          <span class="ml-4">X {{ partItem.qty }}</span>
        </div>
        <div class="mt-4 text-xs">参考信息：</div>
        <div class="item_image m-t-2">
          <el-image
            style="width: 100%; height: 100%"
            v-if="partItem.partImgUrl"
            :src="partItem.partImgUrl"
            :preview-src-list="[partItem.partImgUrl]"
          >
          </el-image>
          <img v-else :src="defaultImg" alt="" />
        </div>
        <div class="mt-8 text-xs">
          <span class="mr-4">{{ partItem.partName }}</span>
          <span>{{ partItem.partCode }}</span>
        </div>
        <!-- <div class="text-xs">
          <span class="mr-4">4S参考价</span>
          <span class="color_red">{{ partItem.price4s }}元</span>
        </div> -->
      </div>

      <div class="flex-1 overflow-auto flex flex-col">
        <el-table
          :data="partItem.items"
          border
          stripe
          size="small"
          class="table-base"
          height="250"
          style="width: 100%;"
          :ref="'multipleTable' + s_index"
          @selection-change="
            (val) => handleSelectionChange(val, 'multipleTable' + s_index)
          "
        >
          <template v-for="(column, c_index) in detailColumns">
            <el-table-column
              v-if="column.prop == 'checkbox'"
              :key="c_index"
              :type="column.type"
              :align="column.align"
              :label="column.label"
              :prop="column.prop"
              :min-width="column.width"
              :fixed="column.fixed"
            >
            </el-table-column>
            <el-table-column
              v-else
              :key="c_index"
              :type="column.type"
              :align="column.align"
              :label="column.label"
              :prop="column.prop"
              :min-width="column.width"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="column.prop == 'flags'">
                  <img
                    v-if="scope.row.flags > 0"
                    :src="selectedUrl"
                    alt=""
                    class="select_img"
                  />
                </span>
                <span v-else>{{ scope.row[column.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
//查看报价
const lookOfferColumns = [
  {
    prop: "checkbox",
    type: "selection",
    align: "center",
    width: "55",
    fixed: "left",
  },
  {
    prop: "quality",
    label: "品质",
    width: "100",
  },
  {
    prop: "price",
    label: "单价(元)",
    width: "100",
  },
  {
    prop: "partName",
    label: "配件名称",
    width: "110",
  },
  {
    prop: "partCode",
    label: "配件编码",
    width: "110",
  },
  {
    prop: "productionPlace",
    label: "产地",
    width: "80",
  },
  {
    prop: "brand",
    label: "配件品牌",
    width: "100",
  },
  {
    prop: "stockQty",
    label: "库存",
    width: "60",
  },
  {
    prop: "guaranteePeriod",
    label: "质保(天)",
    width: "80",
  },
  {
    prop: "deliveryPeriod",
    label: "供货周期 (天)",
    width: "110",
  },
  {
    prop: "remarks",
    label: "备注",
    width: "80",
  },
  {
    prop: "flags",
    label: "中标",
    width: "60",
  },
];
export default {
  name: "partsList",
  props: {
    partsList: {
      type: Array,
      require: true,
    },
    selectOfferParts: {
      type: Number,
      default: 0, //1 全选已报价商品 2 选择中标商品
    },
  },
  data() {
    return {
      list: [],
      defaultImg: require("@/assets/maintenance/default-parts.png"),
      detailColumns: lookOfferColumns,
      multipleSelection: [],
      selectedUrl: require("@/assets/img/inquiry/Check.png"),
      selectedOffer: 0,
      objTable:{}
    };
  },
  watch: {
    partsList: {
      handler(val) {
        this.list = JSON.parse(JSON.stringify(val));
      },
      deep: true,
    },
    // list: {
    //   handler(val) {
    //     this.$emit("changeDetails", val);
    //   },
    //   deep: true,
    // },
    selectOfferParts(val) {
      if (val) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.list.forEach((p, s_i) => {
              let mulTable = "multipleTable" + s_i;
              let elTable = this.$refs[mulTable][0];
              if (p.items && p.items.length) {
                elTable.clearSelection();
                p.items.forEach((q) => {
                  if (val == 1) {
                    elTable.toggleRowSelection(q);
                  }
                  if (q.flags > 0 && val == 2) {
                    elTable.toggleRowSelection(q);
                  }
                });
              }
            });
          }, 200);
        });

        this.$forceUpdate();
      }
    },
  },

  mounted() {
    this.list = JSON.parse(JSON.stringify(this.partsList));
    this.selectedOffer = this.selectOfferParts;
    // console.log("selectOfferParts", this.selectOfferParts);
  },
  methods: {
    handleSelectionChange(val, refTable) {
      // console.log("val", val, refTable);
      this.multipleSelection = [];
      this.objTable[refTable] = val;
      for(let k in this.objTable){
        this.multipleSelection = this.multipleSelection.concat(this.objTable[k]);
      }
      // console.log("objTable", this.objTable);
      // console.log("multipleSelection", this.multipleSelection);
      this.$emit("selectionDetails", this.multipleSelection);
    },
    renderHeight(partItem) {
      let hStr = 208;
      if (partItem.items) {
        let len = partItem.items.length;
        if (len == 0 || len <= 3) {
          hStr = 208;
        } else if (len > 3 && len <= 10) {
          hStr = 194 + (len - 2) * 40;
        } else if (len > 10) {
          hStr = 194 + (10 - 2) * 40;
        }
      } else {
        hStr = 208;
      }
      return hStr + "px";
    },
  },
};
</script>

<style lang="less" scoped>
.part_list {
  flex: 1;
  overflow-y: auto;
}
.partsItem {
  border: 1px solid #ededed;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 20px;
  .item_info {
    padding: 12px;
    width: 167px;
    background: #f4f4f4;
    border-radius: 2px;
    .part_qty {
      width: 20px;
      display: inline-block;
    }
  }
  .color_red {
    color: #ff4d4f;
  }
  .item_image {
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .select_img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
}
</style>
