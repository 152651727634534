<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :modal="true"
    :close-on-click-modal="false"
    width="30%"
  >
    <div class="area_tree">
      <el-tree
        :data="areaData"
        show-checkbox
        :check-strictly="checkStrictly"
        node-key="value"
        ref="tree"
        :default-expanded-keys="defaultExpandedIds"
        :default-checked-keys="defaultCheckedIds"
        :props="defaultProps"
      >
      </el-tree>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureFn">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAddressList } from "@/utils/splicingData";

export default {
  props: {
    dialogTitle: String,
    checkStrictly: {
      type: Boolean,
      default: false,
    }, //在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false
    // defaultExpandedIds: Array, //默认展开的节点 eg:[2,3]
    // defaultCheckedIds: Array, //默认选中的节点 eg:[5]
    areaIds: Array, //默认选中的节点 eg:[5]
  },
  data() {
    return {
      dialogVisible: false,
      areaData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      defaultExpandedIds: [], //默认展开的节点 eg:[2,3]
      defaultCheckedIds: [], //默认选中的节点 eg:[5]
      checkList: [],
    };
  },
  watch: {
    areaIds: {
      handler(val) {
        // this.areaData = getAddressList();
        setTimeout(() => {
          this.defaultExpandedIds = val;
          this.defaultCheckedIds = val;
          this.$forceUpdate();
        }, 200);
      },
      deep: true,
    },
  },
  created() {
    //获取地址列表
    this.areaData = getAddressList();
  },
  mounted() {},
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    sureFn() {
      this.$emit(
        "change",
        this.$refs.tree.getCheckedKeys(),
        this.$refs.tree.getCheckedNodes()
      );
    },
  },
};
</script>

<style lang="less" scoped>
.area_tree {
  height: 400px;
  overflow: auto;
}
</style>
