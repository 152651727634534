import request, { SERVICE_MODULE_MAP } from "@/utils/request";
//获取待审核平台往来单位列表
export function getCooperatorAuditList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/audit-list",
    method: "get",
    params,
  });
}
//获取erp往来单位列表
export function getCooperatorErpList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/erp",
    method: "get",
    params,
  });
}
//获取平台往来单位列表
export function getCooperatorList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/list",
    method: "get",
    params,
  });
}
//获取微信带参二维码
export function getCooperatorCode(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/wx/qrcode/get",
    method: "get",
    params,
  });
}
//店铺粉丝列表
export function getCooperatorFansList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/follow-stores/fans",
    method: "get",
    params,
  });
}
//平台往来单位绑定ERP往来单位
export function postCooperatorBind(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/bind",
    method: "post",
    data,
  });
}
//审核平台往来单位
export function postCooperatorAudit(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/audit",
    method: "post",
    data,
  });
}
//拒绝平台往来单位申请
export function postCooperatorRefuse(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/refuse",
    method: "post",
    data,
  });
}
//将用户设置为平台往来单位，并绑定erp本地往来单位
export function postCooperatorUserbind(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/user-bind",
    method: "post",
    data,
  });
}