import { render, staticRenderFns } from "./offerDetail.vue?vue&type=template&id=12f9e4ed&scoped=true&"
import script from "./offerDetail.vue?vue&type=script&lang=js&"
export * from "./offerDetail.vue?vue&type=script&lang=js&"
import style0 from "./offerDetail.vue?vue&type=style&index=0&id=12f9e4ed&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f9e4ed",
  null
  
)

export default component.exports