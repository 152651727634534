<template>
  <!-- 询价单 -->
  <div class="enquiry h-full w-full  flex flex-col">
    <SearchTemplate
      :formData="formData"
      labelWidth="80px"
      :placeholderText="placeholder"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    >
      <template slot="more">
        <el-form-item label="询价时间">
          <el-date-picker
            v-model="joinTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="dateChangeFn"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formData.status" multiple placeholder="请选择">
            <el-option
              v-for="item in statusOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品质要求">
          <el-select v-model="formData.quality" multiple placeholder="品质要求">
            <el-option
              v-for="item in qualityOptions"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票类型">
          <el-select
            v-model="formData.invoiceType"
            multiple
            placeholder="发票类型"
          >
            <el-option
              v-for="item in invoiceTypeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区">
          <el-select
            v-model="formData.pushArea"
            clearable
            placeholder="所在地区"
          >
            <el-option
              v-for="item in areaOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-checkbox v-model="formData.onlyEnquiry">仅看询价中的</el-checkbox>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-checkbox v-model="formData.onlySelected"
            >仅看已中标的</el-checkbox
          >
        </el-form-item>
      </template>
      <template #right>
        <div class="flex items-center">
          <div style="color:#FF6E4C;" class="mr-4">
            报价率：{{ renderRate(Stats.offeredRate) }}% | 中标率：{{
              renderRate(Stats.selectedRate)
            }}% | 报价完整率：{{ renderRate(Stats.completeRate) }}% |
            平均报价时长：{{ avgDurationTime }}
          </div>
          <el-popover placement="left" width="550" trigger="click">
            <el-table :data="gridData">
              <el-table-column
                width="90"
                prop="indicators"
                label="指标"
              ></el-table-column>
              <el-table-column
                width="250"
                prop="nounMeaning"
                label="名词释义"
              ></el-table-column>
              <el-table-column prop="formula" label="公式"></el-table-column>
            </el-table>
            <i
              class="el-icon-question text-lg cursor-pointer"
              style="color: #999;"
              slot="reference"
            ></i>
          </el-popover>
        </div>
      </template>
    </SearchTemplate>
    <el-table
      :data="tableData"
      border
      stripe
      class="table-base m-t-16"
      size="small"
      style="width: 100%"
      height="400px"
    >
      <el-table-column prop="status" label="状态" min-width="70">
        <template slot-scope="scope">
          <div
            class="status_box"
            :class="[scope.row.selectedOfferCount ? 'min_h' : '']"
          >
            <div
              class="wait_offer title_label"
              v-if="scope.row.offerStatus == 'waitOffer'"
            >
              待报价
            </div>
            <div
              class="has_offer title_label"
              v-else-if="scope.row.offerStatus == 'offered'"
            >
              已报价
            </div>
            <div
              class="wait_publish title_label"
              v-else-if="scope.row.offerStatus == 'givenUp'"
            >
              已放弃
            </div>
            <div
              class="equirying m-t-4"
              v-if="
                scope.row.subStatus == 'enquirying' &&
                  scope.row.offerStatus != 'givenUp'
              "
            >
              询价中
            </div>
            <div
              class="equirying_stop  m-t-4"
              v-else-if="
                scope.row.subStatus == 'stoped' &&
                  scope.row.offerStatus != 'givenUp'
              "
            >
              停止询价
            </div>
            <div class="winning" v-if="scope.row.selectedEnquiryDetailCount">
              已中标
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="enquiryCode" label="单据号" min-width="110">
      </el-table-column>
      <el-table-column prop="companyName" label="询报价人" min-width="220">
        <template slot-scope="scope">
          <div class="enquiry_offer">
            <div class="flex items-center flex-wrap">
              <span class="en_type mr-8">询</span>
              <span class="mr-8">
                {{ renderName(scope.row.companyName) || "--" }}
              </span>
              <span
                class="companyType mr-8"
                :class="[
                  scope.row.companyId ? renderCompanyClass(scope.row) : '',
                ]"
                v-if="scope.row.companyId"
              >
                {{ renderCompanyType(scope.row) }}
              </span>
              <!-- 往来单位名称 -->
              <span class="mr-8">{{ renderName(scope.row.customerName) }}</span>
              <span
                v-if="scope.row.offerStatus != 'givenUp' && companyErpBinded"
              >
                <el-button
                  v-if="!scope.row.customerName"
                  type="text"
                  size="mini"
                  @click="bidBangFn(scope.row)"
                >
                  绑定
                </el-button>
                <el-button
                  v-else
                  type="text"
                  size="mini"
                  @click="bidBangFn(scope.row)"
                >
                  修改
                </el-button>
              </span>
            </div>
            <div class="m-t-4">
              <span> {{ scope.row.userName }} - </span>
              <span>{{ scope.row.userPhone }}</span>
              <i
                class="iconfont icon-line_message1 cursor-pointer ml-8"
                @click="sendMsg(scope.row)"
              ></i>
            </div>
            <div class="m-t-4">
              询价时间：{{ renderTime(scope.row.publishTime) }}
            </div>
            <div class="flex items-center m-t-4" v-if="scope.row.offerUserId">
              <span class="offer_type mr-8">报</span>
              <span>
                {{ scope.row.offerUserName }}-{{ scope.row.offerUserPhone }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="factoryBrand" label="车辆信息" min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.enquiryType == 0">
            {{ scope.row.factoryBrand }} {{ scope.row.series }}
            {{ scope.row.year }} {{ scope.row.displacement }}
            {{ scope.row.gearbox }}
          </div>
          <div v-if="scope.row.enquiryType == 1">
            {{
              scope.row.brandsScope && scope.row.brandsScope.length
                ? scope.row.brandsScope.join("、")
                : ""
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="quality" label="品质要求" min-width="80">
        <template slot-scope="scope">
          {{
            scope.row.quality && scope.row.quality.length
              ? scope.row.quality.join("、")
              : "无限制"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="询价内容" min-width="160">
        <template slot-scope="scope">
          {{
            scope.row.content && scope.row.content.length
              ? scope.row.content.join("、")
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作">
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.offerStatus == 'waitOffer' &&
                scope.row.subStatus == 'enquirying'
            "
          >
            <el-button
              type="text"
              size="mini"
              @click="immediateOfferFn(scope.row)"
            >
              立即报价
            </el-button>
          </div>
          <div v-if="scope.row.offerStatus == 'offered'">
            <el-button type="text" size="mini" @click="lookOfferFn(scope.row)">
              查看报价
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="m-t-16 text-right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageInfo.pageSize"
      layout="total,  prev, pager, next, jumper,sizes"
      :total="totalSize"
    >
    </el-pagination>
    <!-- 详情 -->
    <OfferDetail
      ref="OfferDetail"
      :btnType="btnType"
      :offerId="offerId"
      @refresh="refreshFn"
    />
    <!-- 绑定erp弹框 -->
    <BidErp ref="bidErp" :rowData="rowData" @refresh="handleQuery()" />
  </div>
</template>

<script>
import SearchTemplate from "@/components/search/SearchTemplate.vue";
import OfferDetail from "./components/offerDetail.vue";
import BidErp from "./components/bidErp.vue";
import { getDict } from "@/api/basic";
import { offerList } from "@/api/offer";
import { mapState } from "vuex";

const defaultFormData = {
  // page: 0, //# 页码
  // pageSize: 0, //# 页宽
  status: [],
  quality: [],
  startTime: "", ////加入时间起始
  endTime: "", //# 加入时间截止
  keyword: "", //# 关键字
  // receiveAreaId: 0, ////收货地区
  pushArea: "none", //# 推送地区，none:无限制；thisCity:本市；thisProvince:本省
  invoiceType: [], //# 发票类型
  onlyEnquiry: false,
  onlySelected: false,
};

export default {
  name: "offerList",
  components: {
    SearchTemplate,
    OfferDetail,
    BidErp,
  },
  data() {
    return {
      placeholder: "询价人公司、姓名/询价单号/车型品牌",
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      joinTime: [],
      statusOptions: [
        //# 报价状态:waitOffer:待报价;offered:已报价;givenUp:已放弃
        // { name: "全部", code: "all" },
        { name: "待报价", code: "waitOffer" },
        { name: "已报价", code: "offered" },
        { name: "已放弃", code: "givenUp" },
      ],
      qualityOptions: [], //品质
      invoiceTypeOptions: [], //发票类型
      areaOptions: [
        //收货地区
        {
          code: "none",
          name: "无限制",
        },
        {
          code: "thisCity",
          name: "本市",
        },
        {
          code: "thisProvince",
          name: "本省",
        },
      ],
      pageInfo: {
        page: 1,
        pageSize: 20,
      },
      totalSize: 0,
      tableData: [],
      Stats: {
        offeredRate: "0", ////报价率
        selectedRate: "0", //# 中标率
        completeRate: "0", //# 报价完整率
        avgDuration: 0, //# 平均报价时长
      },
      gridData: [
        {
          indicators: "报价率",
          nounMeaning:
            "已报价的询价单占收到的询询价的比例，主要体现商家的报价服务能力",
          formula: "报价率=已报价单数/询价单数*100%",
        },
        {
          indicators: "中标率",
          nounMeaning:
            "已中标的报价单占总报价单的比例，主要体现商家的报价的质量及综合能力",
          formula: "中标率=已中标单数/报价单数*100%",
        },
        {
          indicators: "报价完整率",
          nounMeaning:
            "已报价的配件条数占收到的总询价配件条数的比例，主要体现商家的经营覆盖范围大小",
          formula: "报价完整率=已报价配件条数/询价配件总条数*100%",
        },
        {
          indicators: "平均报价时长",
          nounMeaning:
            "从收到询价单到提交报价单所需的时间为每单的报价时长，通过总时长和单量的比例计算出来的时长为平均报价时长，主要体现商家的报价响应速度",
          formula: "平均报价时长=n/(X1+…Xn) 计算到时-分-秒",
        },
      ],
      rowData: {},
      offerId: 0, //报价id
      btnType: 1, //1 立即报价 2 查看报价
      customerId: 0, //绑定往来单位id
      timer: null,
      operate: null, //是否是通过im消息跳转 query 查看报价 offer 立即报价
    };
  },
  computed: {
    ...mapState({
      companyErpBinded: (state) => state.profile.companyErpBinded,
    }),
    avgDurationTime() {
      let t = this.Stats.avgDuration;
      let day = Math.floor(t / 86400);
      let hour = Math.floor((t / 3600) % 24);
      let min = Math.floor((t / 60) % 60);
      let sec = Math.floor(t % 60);
      let str = "";
      str = `${day}天${hour}时${min}分${sec}秒`;
      return str;
    },
  },
  created() {
    this.getQualityList();
    this.getInvoiceTypeList();
  },
  mounted() {
    // console.log(this.companyErpBinded, 123);
    if (this.$route.query.keywords && this.$route.query.operate) {
      this.formData.keyword = this.$route.query.keywords;
      this.operate = this.$route.query.operate;
      this.initData();
    } else {
      this.initData();
      //设置定时器 5s 请求一次
      // this.timer = setInterval(() => {
      //   this.initData();
      // }, 5000);
    }
  },
  // destroyed() {
  //   clearInterval(this.timer);
  // },
  methods: {
    renderRate(rate) {
      let n = 0;
      if (rate == "1") {
        n = 100;
      } else if (rate == "0") {
        n = 0;
      } else {
        let r = rate.split(".")[1];
        if (r.length == 2) {
          n = Number(r);
        } else if (r.length == 1) {
          n = Number(r + "0");
        }
      }

      return n;
    },
    refreshFn() {
      this.operate = null;
      this.initData();
    },
    renderName(name) {
      return name.length > 8 ? name.substr(0, 7) + "..." : name;
    },
    renderTime(time) {
      return this.$dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    },
    getQualityList() {
      getDict({ type: "quality" }).then((res) => {
        this.qualityOptions = res || [];
      });
    },
    getInvoiceTypeList() {
      getDict({ type: "invoiceType" }).then((res) => {
        this.invoiceTypeOptions = res || [];
      });
    },
    renderCompanyClass(item) {
      let str = "";
      if (item.companyType == "dealer") {
        str = "type_d";
      } else if (item.companyType == "garage") {
        str = "type_g";
      } else if (item.companyType == "manufacturer") {
        str = "type_m";
      }
      return str;
    },
    renderCompanyType(item) {
      let str = "";
      if (item.companyType == "dealer") {
        str = "经销商";
      } else if (item.companyType == "garage") {
        str = "修理厂";
      } else if (item.companyType == "manufacturer") {
        str = "生产厂家";
      }
      return str;
    },
    sendMsg(e) {
      this.$store.dispatch("setChatId", e.userId);
      this.$store.dispatch("changeIMVisible", true);
    },
    initData() {
      let params = {
        ...this.formData,
        keywords: this.formData.keyword,
        ...this.pageInfo,
      };
      delete params.keyword;
      offerList(params).then((res) => {
        this.tableData = res?.Rows || [];
        this.Stats = res?.Stats || [];
        this.totalSize = res?.totalSize;
        if (this.operate) {
          let curRow = this.tableData[0];
          if (curRow.offerStatus == "offered") {
            //im 跳转查看报价
            this.lookOfferFn(curRow);
          }
          if (curRow.offerStatus == "waitOffer") {
            //im 跳转立即报价
            this.immediateOfferFn(curRow);
          }
        }
      });
    },
    handleQuery() {
      this.pageInfo.page = 1;
      this.initData();
    },
    handleReset() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
      this.joinTime = [];
    },
    areaChangeFn(data) {
      this.formData.areaArr = data;
    },
    dateChangeFn(date) {
      if (date) {
        this.formData.startTime = date[0];
        this.formData.endTime = date[1];
      } else {
        this.formData.startTime = "";
        this.formData.endTime = "";
      }
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    immediateOfferFn(row) {
      this.offerId = row.offerId;
      this.btnType = 1;
      this.$refs.OfferDetail.show();
    },
    lookOfferFn(row) {
      this.offerId = row.offerId;
      this.btnType = 2;
      this.$refs.OfferDetail.show();
    },
    bidBangFn(row) {
      this.rowData = { ...row };
      // console.log("绑定", row);
      this.$refs.bidErp.show();
    },
  },
};
</script>

<style lang="less" scoped>
.enquiry {
  /deep/.table-base .el-table__row td:first-child .cell {
    // height: 100%;
  }
  .status_box {
    position: relative;
    height: 100%;
  }
  .min_h {
    min-height: 106px;
  }
  .title_label {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 20px;
    padding: 2px 8px;
    margin-left: -10px;
  }
  .wait_publish {
    width: fit-content;
    background: #bfbfbf;
    border-radius: 0px 29px 29px 0px;
  }
  .wait_offer {
    width: fit-content;
    background: #ff6e4c;
    border-radius: 0px 29px 29px 0px;
  }
  .has_offer {
    width: fit-content;
    background: #3bba98;
    border-radius: 0px 29px 29px 0px;
  }
  .equirying::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #3bba98;
    border-radius: 50%;
    margin-right: 8px;
  }
  .equirying_stop::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #999999;
    border-radius: 50%;
    margin-right: 8px;
  }
  .winning {
    position: absolute;
    left: -27px;
    bottom: 0;
    width: 70px;
    padding: 2px;
    font-size: 11px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 16px;
    background: #3bba98;
    transform: rotate(45deg);
  }
  .enquiry_offer {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 22px;
    .en_type {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      background: #ff6e4c;
      border-radius: 2px;
      color: #ffffff;
      padding: 2px 8px;
    }
    .companyType {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      line-height: 20px;
      border-radius: 2px;
      padding: 0 2px;
    }
    .type_d {
      border: 1px solid #3bba98;
      background: #e1f5f0;
      color: #3bba98;
    }
    .type_g {
      border: 1px solid #ff6e4c;
      background: #ffeeeb;
      color: #ff6e4c;
    }
    .type_m {
      border: 1px solid #ff6e4c;
      background: #ffeeeb;
      color: #ff6e4c;
    }
    .offer_type {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      background: #3bba98;
      border-radius: 2px;
      color: #ffffff;
      padding: 2px 8px;
    }
  }
}
</style>
