<template>
  <el-drawer
    size="80%"
    :visible.sync="drawer"
    :show-close="false"
    :withHeader="false"
    :wrapperClosable="false"
    :destroy-on-close="true"
  >
    <div class="enquiry_detail flex flex-col">
      <div class="border_bottom flex justify-between items-center py-16 pr-16">
        <div class="flex items-center">
          <div
            class="wait_offer title_label"
            v-if="formData.offer.status == 'waitOffer'"
          >
            待报价
          </div>
          <div
            class="has_offer title_label"
            v-else-if="formData.offer.status == 'offered'"
          >
            已报价
          </div>
          <div
            class="equirying  ml-8"
            v-if="formData.subStatus == 'enquirying'"
          >
            询价中
          </div>
          <div
            class="equirying_stop ml-8"
            v-else-if="formData.subStatus == 'stoped'"
          >
            停止询价
          </div>
        </div>
        <div>
          <span>询价日期:{{ renderTime(formData.publishTime) }} </span>
          <i
            class="iconfont icon-line_fork cursor-pointer ml-8"
            @click="close"
          ></i>
        </div>
      </div>
      <div class="flex-1 px-16 py-16  flex flex-col overflow-y-scroll">
        <div class="enquiry_info mb-16">
          <el-row>
            <el-col :span="8">
              <div>
                <span class="color_6">单号：</span>
                <span>{{ formData.enquiryCode }}</span>
              </div>
              <div class="mt-4 flex">
                <span class="color_6">车型：</span>
                <div class="flex-1">
                  <span v-if="formData.enquiryType == 0">
                    {{ formData.vehBrand }} {{ formData.factoryBrand }}
                    {{ formData.series }} {{ formData.year }}
                    {{ formData.displacement }} {{ formData.gearbox }}
                  </span>
                  <span v-if="formData.enquiryType == 1">
                    {{
                      formData.brandsScope && formData.brandsScope.length
                        ? formData.brandsScope.join("、")
                        : ""
                    }}
                  </span>
                </div>
              </div>
              <div class="mt-4">
                <span class="color_6">VIN码：</span>
                <span>{{ formData.vin }}</span>
              </div>
              <div class="mt-4">
                <span class="color_6">备注：</span>
                <span>{{ formData.remarks }}</span>
              </div>
            </el-col>
            <el-col :span="7" :offset="1">
              <div class="flex">
                <span class="color_6">公司名称：</span>
                <div class="flex-1">
                  <span class="mr-4">{{ formData.companyName || "--" }}</span>
                  <span
                    class="companyType mr-4"
                    :class="[
                      formData.companyId ? renderCompanyClass(formData) : '',
                    ]"
                    v-if="formData.companyId"
                  >
                    {{ renderCompanyType(formData.companyType) }}
                  </span>
                  <!-- 往来单位名称 -->
                  <span class="mr-4">{{ formData.customerName }}</span>
                  <span v-if="companyErpBinded">
                    <el-button
                      v-if="!formData.customerName"
                      type="text"
                      size="mini"
                      @click="bidBangFn"
                    >
                      绑定
                    </el-button>
                    <el-button
                      v-else
                      type="text"
                      size="mini"
                      @click="bidBangFn"
                    >
                      修改
                    </el-button>
                  </span>
                </div>
              </div>
              <div class="flex items-center mt-4">
                <span class="color_6">发布人：</span>
                <span> {{ formData.userName }}-{{ formData.userPhone }} </span>
                <i
                  class="iconfont icon-line_message1 cursor-pointer ml-4"
                  @click="sendMsg(formData)"
                ></i>
              </div>
              <div class="flex items-center mt-4">
                <span
                  class="info_flag mr-4"
                  v-if="
                    formData.invoiceType !== '' &&
                      formData.invoiceType !== '无限制'
                  "
                >
                  {{ formData.invoiceType }}
                </span>
                <span
                  class="info_flag mr-4"
                  v-if="formData.quality && formData.quality.length"
                >
                  {{ formData.quality.join("/") }}
                </span>
                <span
                  class="info_flag"
                  v-if="formData.pushArea && formData.pushArea != 'none'"
                >
                  {{ renderPushArea(formData.pushArea) }}
                </span>
              </div>
              <div class="mt-4">
                <el-button
                  type="text"
                  size="mini"
                  v-if="formData.pictures && formData.pictures.length"
                  @click="lookPictures()"
                >
                  查看图片（{{ formData.pictures.length }}）
                </el-button>
                <span class="color_6" v-else>暂无图片</span>
              </div>
            </el-col>
            <el-col :span="7" :offset="1">
              <div class="flex">
                <span class="color_6">收货信息：</span>
                <div class="flex-1">
                  <div>
                    <span class="mr-8">{{ formData.receiveName }}</span>
                    <span>{{ formData.receivePhone }}</span>
                  </div>
                  <div>
                    <span class="mr-8">{{ formData.receiveAreaName }}</span>
                    <span>{{ formData.receiveAddress }}</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="border_bottom"></div>
        <div class="my-16" v-if="btnType == 1">
          <el-button
            type="primary"
            size="small"
            v-if="companyErpBinded"
            @click="fromErpExtractFn"
          >
            从ERP中提取报价
          </el-button>
        </div>
        <div class="parts_content flex flex-col">
          <PartsList
            v-if="btnType == 1"
            :partsList="formData.details"
            @changeDetails="changeDetailsFn"
          />
          <LookPartsList
            v-else-if="btnType == 2"
            :selectOfferParts="selectOfferParts"
            :partsList="formData.details"
            @selectionDetails="selectionDetailsFn"
          />
          <!-- <div class="text-right mt-8" v-if="formData.details.length">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageInfo.page"
              :page-sizes="pageInfo.pageSizes"
              :page-size="pageInfo.pageSize"
              :total="pageInfo.total"
              layout="prev, pager, next,sizes"
            >
            </el-pagination>
          </div> -->
        </div>
        <!-- 发货信息 -->
        <div class="delivery_info my-8">
          <div class="enq_h1">发货信息</div>
          <div class="my-16">
            <span class="enq_h2 mr-8">发货地区</span>
            <SelectArea
              style="width:376px;"
              placeholder="请选择区域"
              :checkStrictly="false"
              :disabled="btnType == 2"
              :areaId="formData.offer.deliverAreaId"
              @change="selectAreaFn"
            />
          </div>
        </div>
        <!-- 报价备注 -->
        <div class="offer_remark my-8">
          <div class="enq_h1">报价备注</div>
          <div class="my-16 flex">
            <span class="enq_h2 mr-8">上传图片</span>
            <div class="flex-1" v-if="btnType == 1">
              <UploadFileImg
                tip="最多上传10张图片"
                :maxSize="1"
                listType="picture-card"
                :limit="10"
                :fileList="pictures"
                :isMultiple="false"
                :showFileList="true"
                :hideFlag="true"
                @getFileList="(fileList) => getFileList(fileList, 'pictures')"
              />
            </div>
            <div class="flex-1" v-if="btnType == 2">
              <span
                class="flex"
                v-if="formData.offer.pictures && formData.offer.pictures.length"
              >
                <template v-for="(img, index_i) in formData.offer.pictures">
                  <el-image
                    :src="img"
                    alt=""
                    :key="'img' + index_i"
                    fit="cover"
                    class="w-56 h-56 mr-8"
                    :preview-src-list="formData.offer.pictures"
                  />
                </template>
              </span>
              <span class="text-black-100" v-else>暂无图片</span>
            </div>
          </div>
          <div class="mt-16 flex">
            <span class="enq_h2 mr-8">备注文字</span>
            <el-input
              style="width:376px;"
              type="textarea"
              :disabled="btnType == 2"
              :autosize="{ minRows: 2, maxRows: 5 }"
              placeholder="请输入您想说的话"
              v-model="formData.offer.remarks"
            >
            </el-input>
          </div>
        </div>
      </div>
      <!-- 立即报价 -->
      <div
        class="immediate_offer flex justify-between px-16 py-8 border_top"
        v-if="btnType == 1"
      >
        <div class="footer_l">
          <!-- <el-pagination
            background
            :total="5"
            size="small"
            layout="prev, pager, next"
          >
          </el-pagination> -->
        </div>
        <div class="footer_r ">
          <el-button plain size="small" @click="backFn">
            返回列表
          </el-button>
          <el-button plain size="small" @click="giveUpOfferFn">
            放弃报价
          </el-button>
          <el-button type="primary" plain size="small" @click="saveFn">
            保存
          </el-button>
          <el-button type="primary" size="small" @click="submitFn">
            提交报价单
          </el-button>
        </div>
      </div>
      <!-- 查看报价 -->
      <div
        class="look_offer  flex justify-between px-16 py-8 border_top"
        v-else
      >
        <div class="footer_l flex items-center">
          <!-- <el-pagination
            background
            :total="5"
            size="small"
            layout="prev, pager, next"
          >
          </el-pagination> -->
          <el-radio-group v-model="selectOfferParts" class="ml-16">
            <el-radio :label="1">全选已报价商品</el-radio>
            <el-radio :label="2">选择中标商品</el-radio>
          </el-radio-group>
        </div>
        <div class="footer_r ">
          <el-button plain size="small" @click="backFn">
            返回列表
          </el-button>
          <el-button type="primary" plain size="small" @click="exportOfferFn">
            导出报价
          </el-button>
          <el-button
            type="primary"
            v-if="companyErpBinded"
            size="small"
            @click="createSalesFn"
          >
            生成销售单
          </el-button>
        </div>
      </div>
    </div>
    <BidErp ref="bidErp" :rowData="formData" @refresh="getInit()" />
    <div class="demo-image__preview">
      <el-image
        ref="imgpreview"
        style="width: 0px; height: 0px"
        :src="enquiryUrl"
        :preview-src-list="srcList"
      >
      </el-image>
    </div>
  </el-drawer>
</template>

<script>
import PartsList from "./partsList.vue";
import LookPartsList from "./lookPartsList.vue";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import UploadFileImg from "@/components/Upload/UploadFileImg";
import BidErp from "./bidErp.vue";
import {
  offerDetail,
  exportOfferDetail, //导出报价明细
  createOfferSale, //创建销售单
  offerSave, //保存报价单
  offerSubmit, //提交报价
  offerCancel, //放弃报价
} from "@/api/offer";
import { downloadBlob } from "@/utils/tool";
import { mapState } from "vuex";

export default {
  name: "offer_detail",
  components: {
    PartsList,
    LookPartsList,
    SelectArea,
    UploadFileImg,
    BidErp,
  },
  props: {
    offerId: {
      type: Number,
      require: true,
    },
    btnType: {
      type: Number,
      default: 1, //1 立即报价 2 查看报价
    },
  },
  data() {
    return {
      drawer: false,
      pageInfo: {
        page: 1,
        pageSize: 10,
        total: 1000,
        pageSizes: [10, 20, 30, 40],
      },
      formData: {
        //返回的数据
        enquiryCode: "", //询价单号
        enquiryType: 0, //询价类型，0:车型询价，1:范围询价，2:通信快速询价
        userId: 0, //发布者用户ID（users.id）
        companyId: 0, //发布者公司ID (companies.id）
        status: 0, //状态,1:待发布;2:待报价;3:已报价;
        subStatus: "", //子状态,enquirying:询价中;stoped:停止询价;
        vehBrand: "", //汽车品牌
        factoryBrand: "", //汽车厂牌
        series: "", //车系
        year: "", //年款
        displacement: "", //排量
        gearbox: "", //变速器
        vin: "", //vin码
        brandsScope: [
          //品牌范围
        ],
        pictures: [
          //上传的图片
        ],
        remarks: "", //说明
        invoiceType: "", //发票类型,存中文
        quality: [
          //品质说明,存中文
        ],
        pushArea: "", //推送地区，none:无限制；thisCity:本市；thisProvince:本省
        receiveAreaId: 0, //收货地址所在区域县级ID
        receiveName: "", //收货地址中联系人
        receivePhone: "", //收货地址中联系电话
        receiveAddress: "", //收货地址中详细地址
        receiverCount: 0, //接收者数量
        publishTime: "", //询价发布时间
        offeredCount: 0, //已报价人数
        unreadOfferCount: 0, //未读报价单数
        selectedOfferCount: 0, //中标商家数
        createdBy: 0, //创建者
        createdAt: "", //创建时间
        updatedBy: 0,
        updatedAt: "",
        companyName: "", //询价公司名称
        companyType: "", //询价公司类型
        customerName: "", //绑定客户名称
        userName: "", //询价人名称
        userPhone: "", //询价人电话
        offer: {
          //报价信息
          deliverAreaId: 0, //发货地区，0表示未设置
          pictures: [
            //图片地址数组
          ],
          remarks: "", //备注
        },
        details: [
          // {
          //   id: 0, //自增ID
          //   enquiryCode: "", //单据号
          //   partId: 0, //配件ID/软件内配件Id
          //   partName: "", //配件名称,询价关键字
          //   partCode: "", //配件编码
          //   partType: "", //配件类型，hand:手动填写；choose:从基础分类中选取；searchChoose:配件查询中选择
          //   poperty: "", //配件性质
          //   brand: "", //配件品牌
          //   productionPlace: "", //产地
          //   quality: "", //品质说明,erp保险认证分类,中文
          //   qty: "0", //数量,默认为1
          //   stdPartName: "", //标准配件名称
          //   stdPartCode: "", //标准配件oe号
          //   price4s: "0", //4S价
          //   createdBy: 0, //创建者
          //   createdAt: "0001-01-01T00:00:00Z", //创建时间
          //   updatedBy: 0, //更新者
          //   "*updatedAt": "0001-01-01T00:00:00Z", //更新时间
          //   items: [
          //     //报价信息
          //     {
          //       id: 0, //自增ID
          //       offerId: 0, //接收的询价主键ID
          //       enquiryDetailId: 0, //询价单详情主键ID
          //       partId: 0, //配件ID/软件内配件Id
          //       property: "", //配件性质
          //       quality: "", //品质说明，存中文
          //       "*price": "0", //价格
          //       partName: "", //配件名称
          //       partCode: "", //配件编码
          //       productionPlace: "", //配件产地
          //       brand: "", //配件品牌
          //       stockQty: "0", //报价当时的库存数量，仅展示用，也可不存
          //       guaranteePeriod: 0, //质保期
          //       deliveryPeriod: 0, //供货周期
          //       remarks: "", //备注
          //       flags: 0, //标记位,第一位:已中标;
          //       createdBy: 0, //创建者
          //       createdAt: "0001-01-01T00:00:00Z", //创建时间
          //       updatedBy: 0, //更新者
          //       "*updatedAt": "0001-01-01T00:00:00Z", //更新时间
          //     },
          //   ],
          // },
        ],
      },
      pictures: [],
      selectOfferParts: 2, //1 全选已报价商品 2 选择中标商品
      offerDetails: [],
      customerId: 0, //绑定往来单位id
      selectOfferDetailIds: [],
      loadShow: null,
    };
  },
  watch: {
    pictures: {
      handler(newVal) {
        this.formData.offer.pictures =
          newVal && newVal.length > 0
            ? newVal.map((item) => item.origin || item)
            : [];
      },
      deep: true,
    },
    drawer(val) {
      if (val) {
        this.getInit();
      }
    },
  },
  computed: {
    ...mapState({
      companyErpBinded: (state) => state.profile.companyErpBinded,
    }),
    srcList() {
      return this.formData.pictures || [];
    },
    enquiryUrl() {
      return this.srcList[0];
    },
  },
  created() {
    this.getInit();
  },
  methods: {
    sendMsg(e) {
      this.$store.dispatch("setChatId", e.userId);
      this.$store.dispatch("changeIMVisible", true);
    },
    selectionDetailsFn(data) {
      this.selectOfferDetailIds = data.map((item) => {
        return item.id;
      });
      // console.log("this.selectOfferDetailIds", this.selectOfferDetailIds);
    },
    changeDetailsFn(data) {
      // console.log("data", data);
      let arr = [];
      data.forEach((p) => {
        if (p.items.length) {
          p.items.forEach((q) => {
            arr.push({
              ...q,
              offerId: p.offerId,
              enquiryDetailId: p.id,
            });
          });
        }
      });
      // console.log("arr", arr);
      this.offerDetails = [...arr];
    },
    renderTime(time) {
      return this.$dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    },
    renderCompanyClass(item) {
      let str = "";
      if (item.companyType == "dealer") {
        str = "type_d";
      } else if (item.companyType == "garage") {
        str = "type_g";
      } else if (item.companyType == "manufacturer") {
        str = "type_m";
      }
      return str;
    },
    renderCompanyType(companyType) {
      let str = "";
      if (companyType == "dealer") {
        str = "经销商";
      } else if (companyType == "garage") {
        str = "修理厂";
      } else if (companyType == "manufacturer") {
        str = "生产厂家";
      }

      return str;
    },
    renderPushArea(pushArea) {
      let str = "";
      if (pushArea == "none") {
        str = "无限制";
      } else if (pushArea == "thisCity") {
        str = "本市";
      } else if (pushArea == "thisProvince") {
        str = "本省";
      }
      return str;
    },
    getInit(obj) {
      if (this.offerId == 0) return;
      let params = {
        offerId: this.offerId,
      };
      if (obj) {
        params = { ...obj };
      }
      this.selectOfferParts = 1;
      this.loadShow = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      offerDetail(params).then((res) => {
        this.formData = { ...res };
        if (res.details) {
          let arr = [];
          res.details.forEach((p) => {
            arr.push({
              ...p,
              items: p.items || [],
              offerId: res.offer.id,
            });
          });
          this.formData.details = [...arr];
          this.selectOfferParts = 2;
        }
        if (res.offer.pictures?.length) {
          this.pictures = res.offer.pictures.map((item) => {
            return {
              uid: item,
              url: item,
              origin: item,
            };
          });
        }
        this.loadShow.close();
      });
    },
    lookPictures() {
      document.querySelector(".demo-image__preview .el-image__preview").click();
    },
    show() {
      this.drawer = true;
      this.pictures = [];
    },
    close() {
      this.drawer = false;
      this.$emit("refresh");
    },
    bidBangFn() {
      this.customerId = this.formData.customerId || 0;
      this.$refs.bidErp.show();
    },
    fromErpExtractFn() {
      this.$confirm("从ERP中提取报价，覆盖当前页面的数据？", "提示", {
        confirmButtonText: "确定",
      }).then(() => {
        let params = {
          offerId: this.formData.offer.id, //# 报价ID
          extractFromERP: true, // # 从erp中提取报价,true:是
        };

        this.getInit(params);
      });
    },
    backFn() {
      this.close();
    },
    //放弃报价
    giveUpOfferFn() {
      this.$confirm("确定要放弃报价么？", "提示", {
        confirmButtonText: "确定",
      }).then(() => {
        let params = {
          ...this.formData.offer,
          details: this.offerDetails,
        };
        offerCancel(params).then(() => {
          this.$message.success("已放弃报价");
          this.close();
        });
      });
    },
    // checkDetails(name) {
    //   return this.offerDetails.some((item) => item[name] == "");
    // },
    //保存
    saveFn() {
      // this.checkDetails();
      let params = {
        ...this.formData.offer,
        details: this.offerDetails,
      };

      offerSave(params).then(() => {
        this.$message.success("保存成功！");
        this.getInit();
      });
    },
    submitFn() {
      let params = {
        ...this.formData.offer,
        details: this.offerDetails,
      };
      offerSubmit(params).then(() => {
        this.$message.success("提交报价单成功！");
        this.close();
      });
    },
    //导出报价
    exportOfferFn() {
      if (!this.selectOfferDetailIds.length) {
        this.$message.error("没有选中的商品");
        return;
      }
      let params = {
        id: this.formData.offer.id, //报价ID
        detailIds: this.selectOfferDetailIds, //报价明细ID
      };
      exportOfferDetail(params).then((res) => {
        let name = `报价单_${this.formData.enquiryCode}_${this.$dayjs().format(
          "YYYY-MM-DD"
        )}.xls`;
        downloadBlob(res, name);
        setTimeout(() => {
          this.$message.success(`导出${name}成功！`);
        }, 200);
      });
    },
    //生成销售单
    createSalesFn() {
      // console.log("生成销售单");
      let params = {
        offerId: this.formData.offer.id, //报价ID
        offerDetailIds: this.selectOfferDetailIds, // 报价单详情ID
      };
      createOfferSale(params).then(() => {
        this.$message.success("创建销售单成功！");
      });
    },
    selectAreaFn(data) {
      this.formData.offer.deliverAreaId = data.areaId;
    },
    getFileList(fileList, key) {
      this[key] = fileList;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less" scoped>
.enquiry_detail {
  height: 100vh;
  .border_bottom {
    border-bottom: 1px solid #d9d9d9;
  }
  .border_top {
    border-top: 1px solid #d9d9d9;
  }
  .title_label {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 20px;
    padding: 2px 8px;
  }
  .wait_publish {
    width: fit-content;
    background: #bfbfbf;
    border-radius: 0px 29px 29px 0px;
  }
  .wait_offer {
    width: fit-content;
    background: #ff6e4c;
    border-radius: 0px 29px 29px 0px;
  }
  .has_offer {
    width: fit-content;
    background: #3bba98;
    border-radius: 0px 29px 29px 0px;
  }
  .equirying::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #3bba98;
    border-radius: 50%;
    margin-right: 8px;
  }
  .equirying_stop::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #ff4d4f;
    border-radius: 50%;
    margin-right: 8px;
  }
  .color_6 {
    color: #666666;
  }
  .color_3 {
    color: #333333;
  }
  .enquiry_info {
    background: #f4f4f4;
    border-radius: 2px;
    padding: 16px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 20px;
    .companyType {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      line-height: 20px;
      border-radius: 2px;
      padding: 1px 0px;
    }
    .type_d {
      border: 1px solid #3bba98;
      background: #e1f5f0;
      color: #3bba98;
    }
    .type_g {
      border: 1px solid #ff6e4c;
      background: #ffeeeb;
      color: #ff6e4c;
    }
    .type_m {
      border: 1px solid #ff6e4c;
      background: #ffeeeb;
      color: #ff6e4c;
    }
    .el-button--text {
      font-size: 14px;
      padding: 0;
    }
    .info_flag {
      padding: 1px 8px;
      background: #f4f4f4;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
  .enq_h1 {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 24px;
  }
  .enq_h2 {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 22px;
  }
  .parts_content {
    max-height: 662px;
  }
  .footer_r {
    margin-right: 72px;
  }
}
</style>
