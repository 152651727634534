<template>
  <div class="bid_manage">
    <!-- 报价管理 -->
    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的询价单" name="myEnquiry">
        <myEnquiry v-if="activeName === 'myEnquiry'" source="offerManage" />
      </el-tab-pane>
      <el-tab-pane label="我的报价单" name="offer">
        <Offer v-if="activeName === 'offer'" />
      </el-tab-pane>
      <el-tab-pane label="报价设置" name="priceSetting">
        <PriceSetting v-if="activeName === 'priceSetting'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Offer from "./offer.vue";
import PriceSetting from "./priceSetting.vue";
import myEnquiry from "@/views/home/myEnquiry/index.vue";

export default {
  name: "bid_manage",
  components: {
    Offer,
    PriceSetting,
    myEnquiry
  },
  data() {
    return {
      activeName: "myEnquiry",
    };
  },
  created() {
    const query = this.$route.query;
    if (query && query.clickType) {
      if (query.clickType === "winOffer" || query.clickType === "receiveEnquiry") {
        this.activeName = "offer";
      }
      if (query.clickType === "receiveOffer") {
        this.activeName = "myEnquiry";
      }
    } else {
      this.activeName = "myEnquiry";
    }
  },
  mounted() {
    // console.log(this.activeName);
  },
  methods: {
    handleClick(tab) {
      if (tab.name === "offer") {
        this.$router.push("/trading/offerManage");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bid_manage {
  height: 100%;
  padding: 0 10px;
  /deep/.el-tabs--card {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  /deep/.el-tabs__content {
    flex: 1;
    padding: 0 16px;
    .el-tab-pane {
      height: 100%;
    }
  }
  /deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    color: @color-link;
    background-color: #fff;
  }
}
</style>
