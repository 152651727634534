import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//询价列表
export function offerList(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/list",
    method: "POST",
    data,
  });
}
//报价明细
export function offerDetail(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/detail",
    method: "get",
    params,
  });
}
//导出报价明细
export function exportOfferDetail(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/detail-export",
    responseType: "blob",
    method: "POST",
    data,
  });
}

//创建销售单
export function createOfferSale(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/create-sale",
    method: "POST",
    data,
  });
}
//保存报价单
export function offerSave(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/save",
    method: "POST",
    data,
  });
}
//提交报价
export function offerSubmit(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/submit",
    method: "POST",
    data,
  });
}
//放弃报价
export function offerCancel(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/cancel",
    method: "POST",
    data,
  });
}
//快速报价
export function offerQuickly(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/quickly",
    method: "POST",
    data,
  });
}
//配件下拉框查询
export function offerSearch(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/offer/search",
    method: "POST",
    data,
  });
}