<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :modal="true"
    :close-on-click-modal="false"
    width="30%"
  >
    <div class="my-16">
      <div class="flex items-center my-16">
        <div class="label">本地往来单位</div>
        <el-select
          class="flex-1"
          v-model="swCooperatorId"
          size="small"
          filterable
          remote
          placeholder="本地往来单位"
          :remote-method="remoteMethod"
          :loading="loading"
          @change="swCooperatorChange"
        >
          <el-option
            v-for="item in swCooperatorOptions"
            :key="item.swCustomerId"
            :label="item.swCustomerName"
            :value="item.swCustomerId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mt-16">
        <span class="color_red">*</span>
        <span class="text-black-100"
          >绑定本地往来单后可在报价时按本地往来单位设置的价格类型进行报价</span
        >
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureFn">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getCooperatorErpList,
  // postCooperatorAudit,
  // postCooperatorBind,
  postCooperatorUserbind,
} from "@/api/cooperator";
import { mapState } from "vuex";

export default {
  props: {
    rowData: Object,
  },
  data() {
    return {
      dialogVisible: false,
      swCooperatorId: null,
      swCooperatorOptions: [
        // {
        //   swCustomerId: 1, //# 往来单位ID
        //   swCustomerName: "往来单位名称1", // # 往来单位名称
        //   related: false,
        //   province: "", //# 省
        //   city: "", //# 市
        //   area: "", //# 区县
        //   contactName: "", // # 联系人
        //   contactPhone: "", // # 联系人电话
        //   isCustomer: true, // # 是否是客户
        //   isSupplier: false, //# 是否是供应商
        //   isLogistics: false, //# 是否是物流公司
        //   isInsuranceCompany: false, // # 是否是保险公司
        // },
        // {
        //   swCustomerId: 2, //# 往来单位ID
        //   swCustomerName: "往来单位名称2", // # 往来单位名称
        //   isCustomer: true, // # 是否是客户
        //   isSupplier: true, //# 是否是供应商
        // },
      ], //erp
      unitType: [],
      loading: false,
      resFlag: false,
      count: 0,
    };
  },
  computed: {
    ...mapState({
      erpBinded: (state) => state.profile?.erpBinded,
    }),
    dialogTitle() {
      let str = "绑定/修改";
      // if (this.erpBinded) {
      //   str = "修改";
      // } else {
      //   str = "绑定";
      // }
      return str;
    },
  },
  watch: {
    dialogVisible(val) {
      // if (val && this.erpBinded) {
      if (val) {
        this.getCooperatorErpListFn();
      }
    },
  },
  methods: {
    remoteMethod(query) {
      // if (this.erpBinded) {
      this.loading = true;
      this.getCooperatorErpListFn(query);
      // } else {
      //   this.swCooperatorOptions = [];
      // }
    },
    getCooperatorErpListFn(name = "") {
      let params = {
        swCustomerName: name,
        isBind: 0, // # 是否绑定:1=已绑定的，-1=未绑定的，其他=全部
        page: 1, //# 页码
        pageSize: 200, //# 页大小
      };
      getCooperatorErpList(params).then((res) => {
        this.loading = false;
        this.swCooperatorOptions = res?.rows || [];
        // if (this.pageType == "auditList" || this.pageType == "myFans") {
        //   let obj = {
        //     swCustomerId: 0,
        //     swCustomerName: "请选择",
        //   };
        //   this.swCooperatorOptions.unshift(obj);
        // }
        if (this.count == 0) {
          this.swCooperatorId = this.rowData.customerId; //2970228
          // this.swCooperatorId = 2970228;//
          this.swCooperatorChange(this.swCooperatorId);
          this.count++;
        }
      });
    },
    show() {
      this.resFlag = false;
      this.count = 0;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    sureFn() {
      if (this.resFlag) return;
      this.resFlag = true;
      // if (this.pageType == "myFans") {
      let params = {
        userId: this.rowData.userId, // # 平台用户ID
        swCooperatorId: this.swCooperatorId, //# 本地往来单位客户id（传0为创建）
        relationType: this.unitType,
      };
      postCooperatorUserbind(params).then(() => {
        this.$message.success("绑定成功");
        this.close();
        this.$emit("refresh");
      }).catch(()=>{
        console.log(111)
        this.resFlag = false;
      });
      // }
    },
    swCooperatorChange(value) {
      if (value) {
        this.resFlag = false;
        let selObj = null;
        this.swCooperatorOptions.forEach((p) => {
          if (p.swCustomerId == value) {
            selObj = p;
          }
        });
        let arrType = [];
        if (selObj?.isCustomer) {
          arrType.push("customer");
        }
        if (selObj?.isSupplier) {
          arrType.push("supplier");
        }
        this.unitType = arrType;
        // this.unitTypeDisabled = true;
      } else {
        this.swCooperatorId = null;
        // let arrType = [];
        // if (this.rowData?.isCustomer) {
        //   arrType.push("customer");
        // }
        // if (this.rowData?.isSupplier) {
        //   arrType.push("supplier");
        // }
        // this.unitType = arrType;
        // this.unitTypeDisabled = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.label {
  width: 140px;
}
.color_red {
  color: #ff4d4f;
}
</style>
