<template>
  <div class="part_list">
    <div
      class="partsItem mb-8 px-16 py-16 flex"
      :style="{ height: renderHeight(partItem) }"
      v-for="(partItem, s_index) in list"
      :key="s_index"
    >
      <div class="item_info mr-8">
        <div class="flex items-center justify-end flex-wrap">
          <span class="flex-1">
            {{ partItem.partName || partItem.stdPartName }}
          </span>
          <span class="ml-4">X {{ partItem.qty }}</span>
        </div>
        <div class="mt-4 text-xs">参考信息：</div>
        <div class="item_image m-t-2">
          <el-image
            style="width: 100%; height: 100%"
            v-if="partItem.partImgUrl"
            :src="partItem.partImgUrl"
            :preview-src-list="[partItem.partImgUrl]"
          >
          </el-image>
          <img v-else :src="defaultImg" alt="" />
        </div>
        <div class="mt-8 text-xs">
          <span class="mr-4">{{ partItem.partName }}</span>
          <span>{{ partItem.partCode }}</span>
        </div>
        <!-- <div class="text-xs">
          <span class="mr-4">4S参考价</span>
          <span class="color_red">{{ partItem.price4s }}元</span>
        </div> -->
      </div>
      <div class="flex-1 overflow-auto flex flex-col">
        <el-table
          :data="partItem.items"
          border
          stripe
          ref="xtable"
          size="small"
          class="table-base"
          height="250"
          style="width: 100%;"
        >
          <template v-for="(column, c_index) in detailColumns">
            <el-table-column
              :key="c_index"
              :type="column.type"
              :align="column.align"
              :label="column.label"
              :prop="column.prop"
              :min-width="column.width"
              :fixed="column.fixed"
              show-overflow-tooltip
            >
              <template slot="header" slot-scope="scope">
                <span class="color_red" v-if="renderHeader(column.prop)">
                  *
                </span>
                <span>{{ scope.column.label }}</span>
              </template>
              <template slot-scope="scope">
                <span v-if="column.prop == 'quality'">
                  <el-select
                    v-model="scope.row.quality"
                    size="mini"
                    collapse-tags
                    style="width:100%"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in qualityOptions"
                      :key="item.code"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </span>
                <span v-else-if="column.prop == 'partName'">
                  <el-autocomplete
                    style="width:100%"
                    size="mini"
                    v-model="scope.row[column.prop]"
                    :fetch-suggestions="
                      (value, cb) => querySearch('name', value, cb)
                    "
                    placeholder="配件名称"
                    :trigger-on-focus="false"
                  ></el-autocomplete>
                </span>
                <span v-else-if="column.prop == 'partCode'">
                  <el-autocomplete
                    style="width:100%"
                    size="mini"
                    v-model="scope.row[column.prop]"
                    :fetch-suggestions="
                      (value, cb) => querySearch('code', value, cb)
                    "
                    placeholder="配件编码"
                    :trigger-on-focus="false"
                  ></el-autocomplete>
                </span>
                <span v-else-if="column.prop == 'price'">
                  <el-input-number
                    size="mini"
                    :precision="2"
                    v-model="scope.row[column.prop]"
                    controls-position="right"
                    style="width:100%"
                    :min="0"
                    :max="999999999"
                  ></el-input-number>
                </span>
                <span
                  v-else-if="
                    column.prop == 'guaranteePeriod' ||
                      column.prop == 'deliveryPeriod'
                  "
                >
                  <el-input-number
                    size="mini"
                    :precision="0"
                    v-model="scope.row[column.prop]"
                    controls-position="right"
                    style="width:100%"
                    :min="0"
                    :max="9999"
                  ></el-input-number>
                </span>
                <span
                  v-else-if="
                    column.prop !== 'controls' && column.prop !== 'stockQty'
                  "
                >
                  <el-input
                    v-model="scope.row[column.prop]"
                    size="mini"
                    placeholder="请输入"
                  ></el-input>
                </span>

                <span v-else-if="column.prop == 'controls'">
                  <el-button
                    type="text"
                    class="color_red"
                    size="mini"
                    @click="delBtnFn(scope, s_index)"
                  >
                    删除
                  </el-button>
                </span>
                <span v-else>{{ scope.row[column.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <div class="mt-8">
          <el-button type="primary" size="mini" plain @click="addFn(s_index)"
            >+追加</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDict } from "@/api/basic";
import { offerSearch } from "@/api/offer";
//立即报价
const immediateOfferColumns = [
  {
    prop: "quality",
    label: "品质",
    width: "150",
  },
  {
    prop: "price",
    label: "单价(元)",
    width: "150",
  },
  {
    prop: "partName",
    label: "配件名称",
    width: "150",
  },
  {
    prop: "partCode",
    label: "配件编码",
    width: "150",
  },
  {
    prop: "productionPlace",
    label: "产地",
    width: "100",
  },
  {
    prop: "brand",
    label: "配件品牌",
    width: "120",
  },
  {
    prop: "stockQty",
    label: "库存",
    width: "80",
  },
  {
    prop: "guaranteePeriod",
    label: "质保(天)",
    width: "100",
  },
  {
    prop: "deliveryPeriod",
    label: "供货周期 (天)",
    width: "110",
  },
  {
    prop: "remarks",
    label: "备注",
    width: "100",
  },
  {
    label: "操作",
    prop: "controls",
    width: "60",
    align: "center",
    fixed: "right",
  },
];
const defaultRowObj = {
  quality: "", //品质
  price: "", //单价
  partName: "", //配件名称
  partCode: "", //配件编码
  productionPlace: "", ////产地
  brand: "", //# 配件品牌
  stockQty: "0", //库存
  guaranteePeriod: 0, //质保(天)
  deliveryPeriod: 0, //供货周期 (天)
  remarks: "", //备注
};
export default {
  name: "partsList",
  props: {
    partsList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      list: [],
      defaultImg: require("@/assets/maintenance/default-parts.png"),
      detailColumns: immediateOfferColumns,
      qualityOptions: [],
      multipleSelection: [],
      selectedUrl: require("@/assets/img/inquiry/Check.png"),
    };
  },
  watch: {
    partsList: {
      handler(val) {
        this.list = JSON.parse(JSON.stringify(val));
        this.list.forEach((p) => {
          if (!p.items.length) {
            p.items.push({ ...defaultRowObj });
          }
        });
        // console.log("list", this.list);
      },
      deep: true,
    },
    list: {
      handler(val) {
        this.$emit("changeDetails", val);
      },
      deep: true,
    },
  },
  created() {
    this.getQualityList();
  },
  mounted() {
    this.list = JSON.parse(JSON.stringify(this.partsList));
  },
  methods: {
    renderHeight(partItem) {
      let hStr = 214;
      if (partItem.items) {
        let len = partItem.items.length;
        if (len == 0 || len < 3) {
          hStr = 214;
        } else if (len >= 3 && len <= 10) {
          hStr = 200 + (len - 2) * 45;
        } else if (len > 10) {
          hStr = 200 + (10 - 2) * 45;
        }
      } else {
        hStr = 214;
      }
      return hStr + "px";
    },
    querySearch(type, value, cb) {
      let params = {
        keywords: value,
        type, // # 关键字类型：name,code
      };
      console.log("params", params);
      offerSearch(params).then((res) => {
        let arr = [];
        if (res && res.length) {
          res.forEach((p) => {
            arr.push({ value: p });
          });
        }
        // 调用 callback 返回建议列表的数据
        cb(arr);
      });
    },
    renderHeader(prop) {
      let flag = false;
      if (
        prop == "quality" ||
        prop == "price" ||
        prop == "partName" ||
        prop == "partCode"
      ) {
        flag = true;
      }
      return flag;
    },
    getQualityList() {
      getDict({ type: "quality" }).then((res) => {
        this.qualityOptions = res || [];
      });
    },
    delBtnFn(scope, index) {
      this.list[index].items.splice(scope.$index, 1);
    },
    addFn(index) {
      if (this.list[index].items.length) {
        let copyRows = JSON.parse(JSON.stringify(this.list[index].items));
        let obj = copyRows[copyRows.length - 1];
        delete obj.id;
        //如果列表中有数据，默认添加最后一条，否则添加模版数据
        this.list[index].items.push(obj);
      } else {
        this.list[index].items.push({ ...defaultRowObj });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.part_list {
  flex: 1;
  overflow-y: auto;
}
.partsItem {
  border: 1px solid #ededed;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 20px;
  .item_info {
    padding: 12px;
    width: 167px;
    max-height: 530px;
    background: #f4f4f4;
    border-radius: 2px;
  }

  .color_red {
    color: #ff4d4f;
  }
  .item_image {
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .select_img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
}
</style>
