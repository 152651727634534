<template>
  <!-- 报价设置 -->
  <div class="priceSetting h-full w-full  flex flex-col">
    <!-- 询价接收范围设置 -->
    <div class="mb-12">
      <DetailTitle class="mb-8" titleName="询价接收范围设置" />
      <div class="mb-8 px-16">
        <span class="mr-8">是否接收询价单</span>
        <el-switch
          v-model="formData.receiveEnquiry"
          active-color="#6589F8"
          :class="[formData.receiveEnquiry ? 'yes' : 'no']"
        >
        </el-switch>
      </div>
      <div class="mb-8 px-16 flex items-center">
        <span class="mr-8">接收询价的车型品牌</span>
        <el-radio-group v-model="formData.useCompanyVehBrands">
          <el-radio :label="true">取公司主营车型品牌</el-radio>
          <el-radio :label="false">自定义选择 </el-radio>
        </el-radio-group>
        <el-select
          class="ml-8 flex-1"
          style=" max-width:600px;"
          :disabled="formData.useCompanyVehBrands"
          v-model="formData.VehBrands"
          multiple
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in vehBrandsOptions"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mb-8 px-16 flex items-center">
        <span class="mr-8">接收询价的区域</span>
        <el-radio-group v-model="formData.useCompanyArea">
          <el-radio :label="true">取公司所属省市</el-radio>
          <el-radio :label="false">自定义选择 </el-radio>
        </el-radio-group>

        <span class="area_box mx-8">
          {{ renderAreaName }}
        </span>
        <!-- <div class="area_box mx-8">
          <div class="flex flex-wrap">
            <div
              class="flex  items-center mx-8 my-2 item_area"
              v-for="(area, index) in formData.areas"
              :key="index"
            >
              <span>{{ area.areaName }}</span>
              <i
                class="iconfont icon-line_fork ml-4 cursor-pointer"
                @click="delAreaFn(index)"
              ></i>
            </div>
          </div>
        </div> -->
        <el-button
          type="primary"
          size="small"
          plain
          :disabled="formData.useCompanyArea"
          @click="addAreaFn"
        >
          + 添加
        </el-button>
      </div>
      <div class="mb-8 px-16">
        <span class="mr-8">接收询价的发票类型</span>
        <el-select
          class="ml-8"
          style="width:240px;"
          v-model="formData.invoiceTypes"
          multiple
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in invoiceTypeOptions"
            :key="item.code"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mb-8 px-16">
        <span class="mr-8">接收询价的品质</span>
        <el-select
          class="ml-8"
          style="width:240px;"
          v-model="formData.qualities"
          multiple
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in qualityOptions"
            :key="item.code"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mb-8 px-16">
        <span class="mr-8">新客户默认享受的价格类型</span>
        <el-select
          class="ml-8"
          style="width:240px;"
          v-model="formData.newCustomerPriceType"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in priceTypeOptions"
            :key="item.code"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <span class="ml-8 tips"
          >收到询价单后点击【立即报价】在报价页面可将询价人绑定到ERP本地往来单位上，绑定后获取报价时则是按照对应的本地往来单位在ERP中使用的价格；若未绑定本地往来单位则取此处设置的价格</span
        >
      </div>
    </div>
    <!-- 自动报价设置 -->
    <div class="mb-12">
      <DetailTitle class="mb-8" titleName="自动报价设置" />
      <div class="mb-8 px-16">
        <span class="mr-8">是否自动报价</span>
        <el-switch
          v-model="formData.autoOffer"
          active-color="#6589F8"
          :class="[formData.autoOffer ? 'yes' : 'no']"
        >
        </el-switch>
      </div>
      <div class="mb-8 px-16">
        <span class="mr-8">自动报价配件库存为0是否放弃报价</span>
        <el-switch
          v-model="formData.zeroStockGiveUpOffer"
          active-color="#6589F8"
          :class="[formData.zeroStockGiveUpOffer ? 'yes' : 'no']"
        >
        </el-switch>
        <span class="ml-8 tips"
          >是：当询价单中的配件匹配到本地库存为0时则跳过该配件只报有库存的配件；否：当询价单中的配件匹配到本地库存为0时则依然按照设定的价格进行报价</span
        >
      </div>
      <div class="mb-8 px-16">
        <span class="mr-8">新客户是否允许自动报价</span>
        <el-switch
          v-model="formData.newCustomerAutoOffer"
          active-color="#6589F8"
          :class="[formData.newCustomerAutoOffer ? 'yes' : 'no']"
        >
        </el-switch>
      </div>
    </div>
    <!-- 报价其他设置 -->
    <div class="mb-12">
      <DetailTitle class="mb-8" titleName="报价其他设置" />

      <div class="mb-8 px-16">
        <span class="mr-8">报价的时效</span>
        <el-select
          class="ml-8"
          style="width:240px;"
          v-model="formData.overdueType"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in overdueTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="footer_btn">
      <div class="button-div">
        <el-button type="primary" size="small" @click="save">保存</el-button>
      </div>
    </div>
    <SelectAreaTree
      ref="areaTree"
      dialogTitle="选择区域"
      :checkStrictly="true"
      :areaIds="formData.areaIds"
      :defaultExpandedIds="formData.areaIds"
      :defaultCheckedIds="formData.areaIds"
      @change="selectAreaFn"
    />
  </div>
</template>

<script>
import DetailTitle from "@/components/manageCom/DetailTitle.vue";
import SelectAreaTree from "@/components/selectArea/SelectAreaTree.vue";
import { getVehBrands, getDict } from "@/api/basic";
import {
  companyOfferSetting,
  getCompanyOfferSetting,
} from "@/api/offerSetting";

export default {
  name: "priceSetting",
  components: {
    DetailTitle,
    SelectAreaTree,
  },
  data() {
    return {
      formData: {
        receiveEnquiry: false, //是否接收询价单
        useCompanyVehBrands: true, //接收询价的车型品牌
        VehBrands: [], //车型品牌

        useCompanyArea: true, //接收询价的区域
        areaIds: [], //接收询价的区域
        areas: [], //接收询价的区域ID和名称，报价详情时该字段有效

        invoiceTypes: [], //发票类型
        qualities: [], //品质
        newCustomerPriceType: "", //价格类型

        autoOffer: true, //是否自动报价
        zeroStockGiveUpOffer: false, //自动报价配件库存为0是否放弃报价
        newCustomerAutoOffer: false, //新客户是否允许自动报价

        overdueType: "", //# 报价过期类型，none:始终有效；oneHour:1小时；oneDay:24小时；threeDay:3天；thirtyDay:30天
      },
      vehBrandsOptions: [
        { label: "大众", value: "dazhong" },
        { label: "奥迪", value: "aodi" },
        { label: "品牌三", value: "pinpai3" },
        { label: "品牌四", value: "pinpai4" },
      ],
      areaIds: [], //接收询价的区域
      invoiceTypeOptions: [], //发票类型
      qualityOptions: [], //品质
      priceTypeOptions: [], //价格类型

      overdueTypeOptions: [
        //报价时效
        { label: "始终有效", value: "none" },
        { label: "1小时", value: "oneHour" },
        { label: "24小时", value: "oneDay" },
        { label: "3天", value: "threeDay" },
        { label: "30天", value: "thirtyDay" },
      ],
    };
  },
  computed: {
    renderAreaName() {
      let arr = [];
      if (this.formData.areas?.length) {
        arr = this.formData.areas.map((item) => {
          return item.areaName;
        });
      }
      return arr.join("、");
    },
  },
  created() {
    this.getVehBrands();
    this.getInvoiceType();
    this.getQuality();
    this.getPriceType();
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    selectAreaFn(areaIds, area) {
      this.formData.areaIds = [...areaIds];
      let areaArr = [];
      area.forEach((p) => {
        areaArr.push({
          areaId: p.value,
          areaName: p.label,
        });
      });
      this.formData.areas = areaArr;
      this.$refs.areaTree.close();
    },
    delAreaFn(index) {
      this.formData.areaIds.splice(index, 1);
      this.formData.areas.splice(index, 1);
    },
    addAreaFn() {
      this.$refs.areaTree.show();
    },
    initSetting() {
      getCompanyOfferSetting()
        .then((res) => {
          if (res) {
            this.formData = res;
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.$message.error(err.message);
          }
        });
    },
    getVehBrands() {
      getVehBrands().then((res) => {
        this.vehBrandsOptions = res || [];
      });
    },
    getInvoiceType() {
      getDict({ type: "invoiceType" }).then((res) => {
        this.invoiceTypeOptions = res || [];
      });
    },
    getQuality() {
      getDict({ type: "quality" }).then((res) => {
        this.qualityOptions = res || [];
      });
    },
    getPriceType() {
      getDict({ type: "priceType" }).then((res) => {
        this.priceTypeOptions = res || [];
      });
    },
    save() {
      companyOfferSetting({ ...this.formData })
        .then(() => {
          this.$message.success("保存成功！");
          this.initSetting();
        })
        .catch((err) => {
          if (err && err.message) {
            this.$message.error(err.message);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.priceSetting {
  height: calc(100% - 58px);
  overflow: auto;
  .el-switch {
    &.yes {
      position: relative;
      &::before {
        content: "是";
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 12px;
        color: #ffffff;
      }
    }
    &.no {
      position: relative;
      /deep/.el-switch__core {
        border: 1px solid #6589f8;
        background: #ffffff;
        &::after {
          background-color: #6589f8;
        }
      }
      &::after {
        content: "否";
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 12px;
        color: #6589f8;
      }
    }
  }
  .area_box {
    display: inline-block;
    max-width: 600px;
    max-height: 200px;
    overflow: auto;
    // border: 1px solid #d9d9d9;
    // border-radius: 2px;
    // padding: 8px;
  }
  .item_area {
    padding: 1px 8px;
    background: #f4f4f4;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    i:hover {
      color: #6589f8;
    }
  }
  .tips {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #999999;
    line-height: 20px;
  }
  .footer_btn {
    position: fixed;
    width: 90%;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px rgba(217, 217, 217, 0.3);
    bottom: 8px;
    z-index: 99;
    .button-div {
      display: flex;
      justify-content: center;
      padding: 12px 0;
      border-top: 1px solid @border-color-base;
    }
  }
}
</style>
